<template>
  <b-form novalidate>
    <b-form-group label="Motivo" label-for="reason">
      <b-textarea
        id="reason"
        v-model="form.reason"
        :state="validation.reason"
      />
      <b-form-invalid-feedback v-if="$v.form.reason.$invalid">
        Informe o motivo do cancelamento
      </b-form-invalid-feedback>
    </b-form-group>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import withFormValidation from '@/mixins/withFormValidation'

export default {
  name: 'ToyRentCancelModalForm',
  mixins: [withFormValidation({ fields: ['reason'] })],
  validations: {
    form: {
      reason: { required }
    }
  },
  props: {
    toy: {
      type: Object,
      required: true
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  destroyed() {
    this.form.reason = ''
  }
}
</script>
