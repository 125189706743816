<template>
  <b-form novalidate>
    <b-form-group label="Escolha o período">
      <PeriodRadioGroup v-model="form.periodId" />
    </b-form-group>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import withFormValidation from '@/mixins/withFormValidation'
import PeriodRadioGroup from '@/components/Periods/PeriodRadioGroup'

export default {
  name: 'ToyRentChangePeriodModalForm',
  components: {
    PeriodRadioGroup
  },
  mixins: [withFormValidation({ fields: ['periodId'] })],
  validations: {
    form: {
      periodId: { required }
    }
  },
  props: {
    toy: {
      type: Object,
      required: true
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  created() {
    this.form.periodId = this.toy.rent.period.id
  },
  destroyed() {
    this.form.periodId = null
  }
}
</script>
