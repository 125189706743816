<template>
  <div>
    <StateRenderer v-bind="state">
      <b-alert
        v-if="state.data.length === 0"
        show
        variant="secondary"
        class="text-center"
      >
        <p>Nenhum brinquedo ou produto cadastrado</p>
        <b-button
          size="sm"
          variant="primary"
          :to="{ name: 'ToyRegister' }"
          class="mr-1"
        >
          <span class="fas fa-plus mr-1" /> Cadastrar Brinquedo
        </b-button>

        <b-button size="sm" variant="primary" :to="{ name: 'ProductRegister' }">
          <span class="fas fa-plus mr-1" /> Cadastrar Produto
        </b-button>
      </b-alert>
      <div v-else>
        <div class="d-flex gap-1 flex-column my-2">
          <b-input
            v-model="filter.search"
            type="search"
            size="lg"
            placeholder="Buscar brinquedo, cliente ou usuário"
          />

          <div class="d-flex flex-wrap gap-1">
            <b-button
              variant="success"
              class="w-100 flex-1"
              @click="setStatusFilter(FILTERS_IDS.AVAILABLE)"
            >
              <span class="fas fa-check" /> Disponíveis
            </b-button>
            <b-button
              variant="primary"
              class="w-100 flex-1"
              @click="setStatusFilter(FILTERS_IDS.RENT)"
            >
              <span class="fas fa-user" /> Alugados
            </b-button>
            <b-button
              variant="danger"
              class="w-100 flex-1"
              @click="setStatusFilter(FILTERS_IDS.DELAYED)"
            >
              <span class="fas fa-clock" /> Atrasados
            </b-button>
            <b-button
              variant="secondary"
              class="w-100 flex-1"
              @click="setStatusFilter(FILTERS_IDS.PAUSED)"
            >
              <span class="fas fa-pause" /> Pausados
            </b-button>
            <b-button
              variant="info"
              class="w-100 flex-1"
              @click="setStatusFilter(FILTERS_IDS.ALL)"
            >
              <span class="fas fa-circle" /> Todos
            </b-button>
          </div>
        </div>
        <b-alert
          v-if="!currentCheckout"
          show
          variant="warning"
          class="text-center"
        >
          <div>
            <p>
              <span class="fas fa-exclamation-triangle mr-1" /> Para iniciar um
              novo aluguel ou vender um produto é preciso abrir o caixa do dia.
            </p>
            <b-button
              size="sm"
              variant="warning"
              :to="{ name: 'CheckoutsIndex' }"
            >
              Abrir Caixa
            </b-button>
          </div>
        </b-alert>

        <b-alert
          v-if="periods.data.length === 0"
          show
          variant="warning"
          class="text-center"
        >
          <div>
            <p>
              <span class="fas fa-exclamation-triangle mr-1" /> Para iniciar um
              novo aluguel é preciso cadastrar pelo menos um Período.
            </p>
            <b-button
              size="sm"
              variant="warning"
              :to="{ name: 'PeriodsIndex' }"
            >
              Cadastrar Período
            </b-button>
          </div>
        </b-alert>

        <b-alert
          v-if="filteredItems.length === 0"
          show
          variant="secondary"
          class="text-center"
        >
          Nenhum brinquedo ou produto encontrado
        </b-alert>

        <div v-else class="d-flex flex-wrap gap-1">
          <template v-for="filteredItem in filteredItems">
            <ToyRentListItem
              v-if="isToy(filteredItem)"
              :key="`toy__${filteredItem.id}`"
              :toy="filteredItem"
              :disabled="!currentCheckout"
              @trigger-action="onTriggerAction"
              @reload="onReload"
              @set-toy-update-interval="onSetToyUpdateInterval"
              @clear-toy-update-interval="onClearToyUpdateInterval"
              @emitPaymentAmountsForMultiFormatPayments="
                onHandlePaymentAmountsUpdate
              "
            />
            <ProductListItem
              v-else-if="isProduct(filteredItem)"
              :key="`product__${filteredItem.id}`"
              :product="filteredItem"
              :disabled="!currentCheckout"
              @trigger-action="onTriggerAction"
            />
          </template>
        </div>
        <ToyRentModal
          v-if="showToyRentModal"
          v-model="isModalOpen"
          :toy="toy"
          :action="action"
          :validations="validations"
          :initial-state="initialState"
          :rent-amount="currentRentAmount"
          :products-amount="currentProductsAmount"
          @ok="onOk"
        />
      </div>
    </StateRenderer>
  </div>
</template>

<script>
/* eslint-disable no-fallthrough */
import { mapGetters, mapState, mapActions } from 'vuex'
import {
  FILTERS_IDS,
  HOME_ITEM_TYPES,
  PAYMENT_METHODS,
  PRODUCT_ACTIONS,
  RENT_ACTIONS,
  RENT_ACTIONS_INITIAL_STATES
} from '@/constants'
import { required } from 'vuelidate/lib/validators'
import {
  calculateRentAmount,
  filterNulledPayments,
  parseToysRents
} from '@/helpers/rents'
import { parseProducts } from '@/helpers/products'
import { filterItems } from '@/helpers/home'
import withAsyncAction from '@/mixins/withAsyncAction'
import services from '@/services'
import StateRenderer from '@/components/StateRenderer'
import ToyRentListItem from './ToyRentListItem'
import ProductListItem from './ProductListItem'
import ToyRentModal from './ToyRentModal'
import { isEmpty, isNaN } from 'lodash/fp'

export default {
  name: 'HomeList',
  components: {
    StateRenderer,
    ToyRentListItem,
    ProductListItem,
    ToyRentModal
  },
  mixins: [
    withAsyncAction({
      key: 'toys',
      initialState: [],
      fetcher: {
        methodName: 'fetchToysRents',
        handler: services.toys.fetchToysRents,
        stateUpdater: ({ currentState }) => parseToysRents(currentState)
      }
    }),
    withAsyncAction({
      key: 'products',
      initialState: [],
      fetcher: {
        methodName: 'fetchProducts',
        handler: services.products.fetchProducts,
        stateUpdater: ({ currentState }) => parseProducts(currentState)
      }
    })
  ],
  data() {
    return {
      hideLoading: false,
      isModalOpen: false,
      action: '',
      initialState: {},
      toy: {},
      validations: {
        reason: { required }
      },
      backgroundFetching: false,
      filter: {
        search: '',
        status: FILTERS_IDS.ACTIVE
      },
      toysUpdateIntervals: {},
      fetchDataInterval: null,
      currentRentAmount: 0,
      currentProductsAmount: 0
    }
  },
  computed: {
    ...mapGetters({
      store: ['store/currentStoreId']
    }),
    ...mapState('periods', ['periods']),
    ...mapState('checkout', ['currentCheckout']),
    ...mapState('store', ['currentStore']),
    ...mapGetters('periods', ['periodsSortedByTime']),
    ...mapGetters('store', ['isShopping']),
    state() {
      const isLoadingToys = this.toys.loading && !this.backgroundFetching

      const loading =
        !this.hideLoading && (isLoadingToys || this.products.loading)
      const error = this.toys.error || this.products.error
      const data = [...this.toys.data, ...this.products.data]

      return {
        loading,
        error,
        data
      }
    },
    filteredItems() {
      const {
        toys: { data: toys },
        products: { data: products },
        filter
      } = this
      const items = [...toys, ...products]

      return filterItems({ items, filter })
    },
    canStartRent() {
      const { currentCheckout, periods } = this

      return currentCheckout && periods.data.length > 0
    },
    showToyRentModal() {
      const { action, toy } = this
      return action && toy
    }
  },
  async created() {
    if (this.isShopping) {
      this.$router.push({ name: 'ReportsIndex' })
      return
    }

    this.FILTERS_IDS = FILTERS_IDS
    this.fetchData()
    this.fetchDataInterval = setInterval(() => this.fetchData(), 10000)
  },
  beforeDestroy() {
    if (this.fetchDataInterval) {
      clearInterval(this.fetchDataInterval)
    }
  },
  methods: {
    onHandlePaymentAmountsUpdate({ rentAmount, productsAmount }) {
      if (
        this.currentRentAmount !== rentAmount ||
        this.currentProductsAmount !== productsAmount
      ) {
        this.currentRentAmount = rentAmount
        this.currentProductsAmount = productsAmount
      }
    },
    ...mapActions('periods', ['fetchPeriods']),
    async fetchData() {
      this.hideLoading = true
      const { store: storeId } = this
      await Promise.all([
        this.fetchPeriods({ storeId }),
        this.fetchToysRents(),
        this.fetchProducts({ storeId })
      ])
      this.hideLoading = false
    },
    isToy(item) {
      return item.type === HOME_ITEM_TYPES.TOY
    },
    isProduct(item) {
      return item.type === HOME_ITEM_TYPES.PRODUCT
    },
    setStatusFilter(status) {
      this.filter.status = status
      this.filter.delayed = false
    },
    onTriggerAction({ action, toy, product, data }) {
      switch (action) {
        case RENT_ACTIONS.START:
        case RENT_ACTIONS.CANCEL:
        case RENT_ACTIONS.TRANSFER:
        case RENT_ACTIONS.ADD_EXTRA_TIME:
        case RENT_ACTIONS.CHANGE_PERIOD:
          this.isModalOpen = true
          this.initialState = RENT_ACTIONS_INITIAL_STATES[action]
          this.$nextTick(() => {
            this.toy = toy
            this.action = action
            this.data = data
          })
          break
        case RENT_ACTIONS.REOPEN_LAST_RENT:
          return this.reopenLastRent(toy)
        case RENT_ACTIONS.CLOSE:
          return this.closeRent(toy, data)
        case RENT_ACTIONS.PAUSE:
          return this.pauseRent(toy)
        case RENT_ACTIONS.RESUME:
          return this.resumeRent(toy)
        case RENT_ACTIONS.ADD_PRODUCT:
          return this.addProduct(toy, data)
        case RENT_ACTIONS.REMOVE_PRODUCT:
          return this.removeProduct(toy, data)
        case PRODUCT_ACTIONS.BUY:
          return this.buyProduct(product, data)
        default:
          return
      }
    },
    onOk({ toy, form }) {
      switch (this.action) {
        case RENT_ACTIONS.START:
          return this.startRent(toy, form)
        case RENT_ACTIONS.PAUSE:
          return this.pauseRent(toy, form)
        case RENT_ACTIONS.CANCEL:
          return this.cancelRent(toy, form)
        case RENT_ACTIONS.TRANSFER:
          return this.transferRent(toy, form)
        case RENT_ACTIONS.ADD_EXTRA_TIME:
          return this.addExtraTime(toy, form)
        case RENT_ACTIONS.CHANGE_PERIOD:
          return this.changePeriod(toy, form)
        case RENT_ACTIONS.CLOSE:
          return this.closeRentMultiplePayment(toy, form)
        default:
          return
      }
    },
    async startRent(
      toy,
      { id, document, name, phone, email, updateCustomer, periodId }
    ) {
      const { currentStoreId: storeId } = this

      const toyId = toy.id

      await services.rents.startRent({
        toyId,
        periodId,
        storeId,
        customer: updateCustomer
          ? {
              id,
              name,
              phone,
              email,
              document
            }
          : { id }
      })

      this.$root.$toast.success('Aluguel iniciado com sucesso')
      this.closeModal()
      this.fetchToysRents()
    },
    async pauseRent(toy) {
      const {
        name,
        rent: { id }
      } = toy

      const result = await this.$bvModal.msgBoxConfirm(
        `Deseja realmente pausar o aluguel de ${name}?`
      )

      if (result) {
        await services.rents.pauseRent({ id })

        this.$root.$toast.success('Aluguel pausado com sucesso')
        this.fetchToysRents()
      }
    },
    async resumeRent(toy) {
      const {
        name,
        rent: { id }
      } = toy

      const message = `Deseja realmente retomar o aluguel de ${name}?`
      const result = await this.$bvModal.msgBoxConfirm(message)

      if (result) {
        await services.rents.resumeRent({ id })

        this.$root.$toast.success('Aluguel retomado com sucesso')
        this.closeModal()
        this.fetchToysRents()
      }
    },
    async closeRent(toy, { hasProducts, elapsedTime, paymentMethod }) {
      const { rent } = toy
      const { id } = rent

      if (paymentMethod) {
        const { currentStore } = this
        const { delayFeePerMinute = 1, toleranceMinutes = 2 } = currentStore
        const { rentAmount, productsAmount } = calculateRentAmount({
          rent,
          elapsedTime,
          hasProducts,
          delayFeePerMinute,
          toleranceMinutes
        })
        const amount = rentAmount + productsAmount
        this.closeRentSinglePayment({ id, paymentMethod, amount, rent })
      } else {
        this.isModalOpen = true
        this.initialState = RENT_ACTIONS_INITIAL_STATES[RENT_ACTIONS.CLOSE]
        this.$nextTick(() => {
          this.toy = toy
          this.action = RENT_ACTIONS.CLOSE
        })
      }
    },
    async closeRentSinglePayment({ id, paymentMethod, amount }) {
      const message = `Deseja realmente encerrar o aluguel do brinquedo ${name} com pagamento em ${PAYMENT_METHODS[paymentMethod].name}?`
      const result = await this.$bvModal.msgBoxConfirm(message)

      if (result) {
        const payments = [
          {
            type: paymentMethod,
            amount
          }
        ]

        const checkoutId = this.currentCheckout.id

        await services.rents.closeRent({ id, checkoutId, payments })
        this.$root.$toast.success('Aluguel encerrado com sucesso')
        this.closeModal()
        this.fetchToysRents()
      }
    },
    async closeRentMultiplePayment(toy, { payments }) {
      const { id } = toy.rent

      const checkoutId = this.currentCheckout.id
      const validPayments = filterNulledPayments(payments)

      await services.rents.closeRent({
        id,
        checkoutId,
        payments: validPayments
      })

      this.$root.$toast.success('Aluguel encerrado com sucesso')
      this.closeModal()
      this.fetchToysRents()
    },
    async reopenLastRent(toy) {
      const {
        name,
        // rent: { id },
        lastRentId: id
      } = toy

      const message = `Deseja realmente reiniciar o último aluguel do brinquedo ${name}?`
      const result = await this.$bvModal.msgBoxConfirm(message)

      if (result) {
        await services.rents.reopenLastRent({ id })
        this.$root.$toast.success('Aluguel reiniciado com sucesso')
        this.closeModal()
        this.fetchToysRents()
      }
    },
    async cancelRent(toy, { reason }) {
      const {
        rent: { id }
      } = toy

      await services.rents.cancelRent({ id, reason })

      this.$root.$toast.success('Aluguel cancelado com sucesso')
      this.closeModal()
      this.fetchToysRents()
    },
    async transferRent(toy, form) {
      const { rent } = toy

      if (!isEmpty(rent?.endedAt) || !isEmpty(rent?.canceledAt)) {
        return
      }

      const message = `Deseja realmente transferir o aluguel do brinquedo ${toy.name} para o brinquedo ${form.toy.label}?`
      const result = await this.$bvModal.msgBoxConfirm(message)

      if (result) {
        const toyId = form.toy.code
        await services.rents.updateRent({ ...rent, toyId })

        this.$root.$toast.success('Aluguel transferido com sucesso')
        this.closeModal()
        this.fetchToysRents()
      }
    },
    async addExtraTime(toy, { time, reason }) {
      const {
        rent: { id }
      } = toy

      await services.rents.addExtraTime({ id, time, reason })

      this.$root.$toast.success('Tempo extra adicionado com sucesso')
      this.closeModal()
      await this.fetchToysRents()

      this.checkPeriod(toy, time)
    },
    async checkPeriod(toy, extraTime) {
      const { rent } = toy

      if (!isEmpty(rent?.endedAt) || !isEmpty(rent?.canceledAt)) {
        return
      }

      const parsedExtraTime = isNaN(extraTime) ? 0 : Number(extraTime)

      const { elapsedTime, rentAmount } = this.data
      const prevPeriod = this.periodsSortedByTime.find(
        ({ id, time, price }) => {
          return (
            id !== rent.period.id &&
            elapsedTime - parsedExtraTime <= time &&
            price < rentAmount
          )
        }
      )

      if (prevPeriod) {
        await services.rents.updateRent({ ...rent, periodId: prevPeriod })
        await this.fetchToysRents()
      }

      this.toy = null
    },
    async changePeriod(toy, { periodId }) {
      const { rent } = toy

      if (!isEmpty(rent?.endedAt) || !isEmpty(rent?.canceledAt)) {
        return
      }

      await services.rents.updateRent({ ...rent, periodId })

      this.$root.$toast.success('Período alterado com sucesso')
      this.closeModal()
      this.fetchToysRents()
      this.toy = null
    },
    async addProduct(toy, product) {
      const quantity = 1

      const message = `Deseja realmente incluir ${quantity} unidade(s) do produto ${product.name} a este aluguel?`
      const result = await this.$bvModal.msgBoxConfirm(message)

      if (result) {
        const {
          rent: { id }
        } = toy

        const productId = product.id
        await services.rents.addProduct({ id, productId, quantity })

        this.$root.$toast.success(`${product.name} incluído com sucesso`)
        this.fetchToysRents()
      }
    },
    async removeProduct(toy, product) {
      const message = `Deseja realmente remover o produto: ${product.name} deste aluguel?`
      const result = await this.$bvModal.msgBoxConfirm(message)

      if (result) {
        const {
          rent: { id }
        } = toy

        const productId = product.id

        await services.rents.removeProduct({ id, productId })

        this.$root.$toast.success(`${product.name} removido com sucesso`)
        this.fetchToysRents()
      }
    },
    async buyProduct(product, { quantity, paymentMethod: type }) {
      const message = `Deseja realmente dar saída em ${quantity} unidade(s) do produto ${product.name} com pagamento em ${PAYMENT_METHODS[type].name}?`
      const result = await this.$bvModal.msgBoxConfirm(message)

      if (result) {
        const productId = product.id
        const amount = product.price * quantity

        const checkoutId = this.currentCheckout.id

        await services.payments.createPayment({
          type,
          amount,
          checkoutId,
          productId
        })
        this.$root.$toast.success(
          `Compra do produto ${product.name} realizada com sucesso`
        )
      }
    },
    async onReload() {
      this.backgroundFetching = true
      await this.fetchToysRents()
      this.backgroundFetching = false
    },
    closeModal() {
      this.toy = null
      this.isModalOpen = false
      this.action = ''
    },
    onSetToyUpdateInterval({ toyId, interval }) {
      if (!this.toysUpdateIntervals[toyId]) {
        this.toysUpdateIntervals[toyId] = interval
      }
    },
    onClearToyUpdateInterval({ toyId }) {
      if (this.toysUpdateIntervals[toyId]) {
        clearInterval(this.toysUpdateIntervals[toyId])
      }
    }
  }
}
</script>
