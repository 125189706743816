<template>
  <b-form novalidate>
    <b-form-group
      label="Tempo em Minutos"
      label-for="time"
      :class="validationClass"
    >
      <b-input-group append="minutos">
        <b-form-input
          id="time"
          v-model="form.time"
          :state="validation.time"
          type="number"
        />
      </b-input-group>
      <b-form-invalid-feedback v-if="$v.form.time.$invalid">
        Informe o tempo em minutos
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Motivo" label-for="reason">
      <b-textarea id="reason" v-model="form.reason" />
    </b-form-group>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import withFormValidation from '@/mixins/withFormValidation'

export default {
  name: 'ToyRentAddExtraTimeModalForm',
  mixins: [withFormValidation({ fields: ['time'] })],
  validations: {
    form: {
      time: { required }
    }
  },
  props: {
    toy: {
      type: Object,
      required: true
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    validationClass() {
      const { $dirty, $invalid } = this.$v.form.time
      return {
        'is-invalid': $dirty && $invalid
      }
    }
  },
  destroyed() {
    this.form.time = ''
    this.form.reason = ''
  }
}
</script>
