<template>
  <StateRenderer :lines="lines" v-bind="periods">
    <slot v-bind="periods" />
  </StateRenderer>
</template>

<script>
import withAsyncAction from '@/mixins/withAsyncAction'
import StateRenderer from '@/components/StateRenderer'
import services from '@/services'

export default {
  name: 'PeriodsFetcher',
  components: {
    StateRenderer
  },
  mixins: [
    withAsyncAction({
      key: 'periods',
      initialState: [],
      fetcher: {
        methodName: 'fetchPeriods',
        handler: services.periods.fetchPeriods
      }
    })
  ],
  props: {
    lines: {
      type: Number,
      default: 1
    }
  },
  created() {
    this.fetchPeriods()
  }
}
</script>
