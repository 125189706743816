<template>
  <Layout>
    <HomeList />
  </Layout>
</template>

<script>
import Layout from '@/views/Layout'
import HomeList from '@/components/Home/HomeList'

export default {
  name: 'HomeIndexView',
  components: { Layout, HomeList }
}
</script>
