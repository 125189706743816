<template>
  <b-form class="toy-rent-close-modal-form" novalidate>
    <ToyRentDetails
      :toy="toy"
      @update-rent-amount="rentAmount = $event"
      @update-products-amount="productsAmount = $event"
    />
    <b-form-group
      label="Formas de Pagamento"
      label-for="payment-type"
      :class="paymentsValidationClass"
    >
      <b-input-group
        v-for="payment in form.payments"
        :key="payment.type"
        class="mb-2"
      >
        <b-input-group-text slot="prepend">
          <span>
            <span :class="payment.icon" class="mr-3" />
            {{ payment.name }}
          </span>
        </b-input-group-text>
        <b-form-input
          v-model="payment.amount"
          type="number"
          placeholder="R$ 0,00"
        />
      </b-input-group>

      <b-form-invalid-feedback v-if="$v.form.payments.$invalid">
        <span v-if="!$v.form.payments.paymentMethod">
          Informe pelo menos uma forma de pagamento
        </span>
        <span v-else-if="!$v.form.payments.totalAmount">
          O valor a ser pago é diferente do valor total do aluguel
        </span>
      </b-form-invalid-feedback>
    </b-form-group>
  </b-form>
</template>

<script>
import withFormValidation from '@/mixins/withFormValidation'
import { PAYMENT_METHODS } from '@/constants'
import { formatCurrency } from '@/helpers/numbers'
import ToyRentDetails from './ToyRentDetails'

export default {
  name: 'ToyRentCloseModalForm',
  components: {
    ToyRentDetails
  },
  filters: {
    formatCurrency
  },
  mixins: [withFormValidation({ fields: ['payments'] })],
  validations: {
    form: {
      payments: {
        paymentMethod: (value) =>
          (value || []).some(({ amount }) => amount > 0),
        totalAmount(value) {
          return (
            (value || []).reduce(
              (acc, { amount }) => (acc += parseFloat(amount || 0)),
              0
            ) === this.rentAmount
          )
        }
      }
    }
  },
  props: {
    toy: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rentAmount: 0,
      productsAmount: 0
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    paymentsValidationClass() {
      const { $dirty, $invalid } = this.$v.form.payments
      return {
        'is-invalid': $dirty && $invalid
      }
    }
  },
  created() {
    // this.form.payments = this.initialState
    this.PAYMENT_METHODS = PAYMENT_METHODS
  },
  methods: {
    formatCurrency
  }
}
</script>

<style lang="scss">
.toy-rent-close-modal-form {
  .input-group-text {
    min-width: 135px;
  }
}
</style>
