<template>
  <PeriodsFetcher :lines="3">
    <template #default="{ data }">
      <b-button
        v-for="period in data"
        :key="period.id"
        :variant="getVariant(period)"
        block
        :active="isActive(period)"
        :disabled="isActive(period)"
        @click="onClick(period)"
      >
        <span v-if="isActive(period)" class="fas fa-check mr-1" />
        {{ period.time }} minutos - {{ period.price | formatCurrency }}
      </b-button>
    </template>
  </PeriodsFetcher>
</template>

<script>
import { parsePeriodRadioGroupOptions } from '@/helpers/periods'
import PeriodsFetcher from './PeriodsFetcher'
import { formatCurrency } from '@/helpers/numbers'

export default {
  name: 'PeriodRadioGroup',
  components: {
    PeriodsFetcher
  },
  filters: {
    formatCurrency
  },
  props: {
    value: {
      type: Number,
      default: null
    },
    validation: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    periodId: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    parsePeriods(periods) {
      return parsePeriodRadioGroupOptions(periods)
    },
    isActive(period) {
      return this.periodId === period.id
    },
    getVariant(period) {
      return this.isActive(period) ? 'primary' : 'outline-primary'
    },
    onClick(period) {
      this.periodId = period.id
    },
    reduce: ({ code }) => code
  }
}
</script>
