import addExtraTime from './ToyRentAddExtraTimeModalForm'
import cancel from './ToyRentCancelModalForm'
import close from './ToyRentCloseModalForm'
import pause from './ToyRentPauseModalForm'
import start from './ToyRentStartModalForm'
import transfer from './ToyRentTransferModalForm'
import changePeriod from './ToyRentChangePeriodModalForm'

const partials = {
  addExtraTime,
  cancel,
  changePeriod,
  close,
  pause,
  start,
  transfer
}

export default partials
