const { FILTERS } = require('@/constants')

const filterItems = ({ items, filter }) =>
  items.filter((item) => {
    const search = filter.search.toLowerCase()
    const name = item.name.toLowerCase()
    const customer = item.rent?.customer?.name?.toLowerCase() || ''

    return (
      (name.includes(search) ||
        name.includes(search) ||
        customer.includes(search)) &&
      FILTERS[filter.status](item)
    )
  })

export { filterItems }
