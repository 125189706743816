<template>
  <b-form-group label="Detalhes do Aluguel" label-for="rent-details">
    <b-card class="mb-2">
      <b-row>
        <b-col>
          <b-form-group label="Cliente">
            <b-input
              class="border-0"
              readonly
              :value="toy.rent.customer.name"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Brinquedo">
            <b-input class="border-0" readonly :value="toy.name" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Período">
            <b-input
              class="border-0"
              readonly
              :value="`${toy.rent.period.time} min - ${formatCurrency(
                toy.rent.period.price
              )}`"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group v-if="delay" label="Atraso">
            <b-input class="border-0" readonly :value="`${delay} minuto(s)`" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group label="Tempo Extra">
        <b-textarea class="border-0" readonly :value="extraTime" no-resize />
      </b-form-group>

      <b-form-group v-if="!hideTotal" label="Valor Total">
        <b-input class="border-0" readonly :value="totalAmount" />
      </b-form-group>
    </b-card>
  </b-form-group>
</template>

<script>
import { formatCurrency } from '@/helpers/numbers'
import { calculateRentAmount, isActive, isPaused } from '@/helpers/rents'
import { differenceInMinutes } from 'date-fns'
import services from '@/services'
import { mapState, mapGetters } from 'vuex'
import { isEmpty } from 'lodash/fp'

export default {
  name: 'ToyRentDetails',
  filters: {
    formatCurrency
  },
  props: {
    toy: {
      type: Object,
      required: true
    },
    delay: {
      type: Number,
      default: null
    },
    hideTotal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      elapsedTime: 0,
      rentAmount: 0,
      productsAmount: 0,
      currentUtcTime: null,
      currentPausedTime: 0,
      intervalId: null
    }
  },
  computed: {
    ...mapState('store', ['currentStore']),
    ...mapGetters('periods', ['periodsSortedByPrice']),
    isActive() {
      return isActive(this.toy.rent)
    },
    isPaused() {
      return isPaused(this.toy.rent)
    },
    hasProducts() {
      return this.toy.rent?.product?.length > 0
    },
    totalAmount() {
      return formatCurrency(this.rentAmount + this.productsAmount)
    },
    extraTime() {
      return this.toy.rent.extraTime
        ?.map(({ minutes }) => `+ ${minutes} minuto(s)`)
        .join('\n')
    }
  },
  watch: {
    'toy.rent': {
      handler() {
        this.setElapsedTime()
        this.setAmount()
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.setElapsedTime()
    this.intervalId = setInterval(() => {
      this.setElapsedTime()
    }, 50000)
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  methods: {
    async getTimeUtc() {
      try {
        const utcTime = await services.stores.fetchTime()
        this.currentUtcTime = new Date(utcTime.currentTime)
      } catch (error) {
        console.error('Erro ao obter horário UTC:', error)
      }
    },
    getPausedTime(includeOngoingPause = true) {
      let totalPausedTime =
        this.toy.rent.extraTime
          ?.filter(({ type }) => type === 'pause')
          .reduce((acc, { minutes }) => (acc += minutes), 0) || 0

      if (this.toy.rent.status === 'paused' && includeOngoingPause) {
        const pausedAt = new Date(this.toy.rent.pausedAt)
        const now = this.currentUtcTime
        const ongoingPauseMinutes = differenceInMinutes(now, pausedAt)
        totalPausedTime += ongoingPauseMinutes
      }
      return totalPausedTime
    },
    async setElapsedTime() {
      await this.getTimeUtc()
      const { createdAt, pausedAt, endedAt, canceledAt } = this.toy.rent
      const startTime = new Date(createdAt)
      const status = this.toy.rent.status
      let endTime

      if (status === 'canceled') {
        endTime = new Date(canceledAt)
      } else if (status === 'closed') {
        endTime = new Date(endedAt)
      } else if (status === 'paused') {
        endTime = new Date(pausedAt)
      } else if (status === 'active') {
        endTime = this.currentUtcTime
      }

      let pausedTime
      if (status === 'paused') {
        pausedTime = this.getPausedTime(false)
        this.currentPausedTime = this.getPausedTime(true)
      } else {
        pausedTime = this.getPausedTime(true)
        this.currentPausedTime = pausedTime
      }

      let elapsedTime = differenceInMinutes(endTime, startTime) - pausedTime
      this.elapsedTime = elapsedTime > 0 ? elapsedTime : 0
      this.setAmount()
    },
    setAmount() {
      if (isEmpty(this.toy.rent)) {
        return
      }

      const { toy, elapsedTime, hasProducts, currentStore } = this
      const { delayFeePerMinute = 1, toleranceMinutes = 2 } = currentStore
      const { rent } = toy

      const { rentAmount, productsAmount } = calculateRentAmount({
        rent,
        elapsedTime,
        hasProducts,
        delayFeePerMinute,
        toleranceMinutes
      })
      this.rentAmount = rentAmount
      this.productsAmount = productsAmount
      this.$emit('update-rent-amount', this.rentAmount)
      this.$emit('update-products-amount', this.productsAmount)
    },
    formatCurrency(a) {
      return formatCurrency(a)
    }
  }
}
</script>
