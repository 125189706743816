<template>
  <b-card no-body :img-src="productImage" :img-alt="product.name" img-top>
    <b-card-title
      class="flex-1 px-2 py-2 m-0 d-flex justify-content-between border-top"
    >
      <span>
        <small>
          {{ product.name }}
        </small>
        <span class="d-flex flex-wrap">
          {{ product.price | formatCurrency }}
        </span>
      </span>
    </b-card-title>
    <b-card-text v-if="!disabled">
      <div class="px-2 mt-1">
        <b-input-group>
          <b-input-group-prepend>
            <b-button
              :disabled="quantity === 1"
              class="rounded-0"
              @click="decrement"
            >
              <span class="fas fa-minus" />
            </b-button>
          </b-input-group-prepend>
          <b-input v-model.number="quantity" type="number" min="1" />
          <b-input-group-append>
            <b-button class="rounded-0" @click="increment">
              <span class="fas fa-plus" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <div class="d-flex flex-wrap justify-content-stretch gap-1 my-2">
          <b-button
            variant="success"
            class="flex-1"
            @click="onTriggerAction(PAYMENT_METHODS_IDS.CASH)"
            >DI</b-button
          >
          <b-button
            variant="warning"
            class="flex-1"
            @click="onTriggerAction(PAYMENT_METHODS_IDS.CREDIT_CARD)"
            >CC</b-button
          >
          <b-button
            variant="info"
            class="flex-1"
            @click="onTriggerAction(PAYMENT_METHODS_IDS.DEBIT_CARD)"
            >CD</b-button
          >
          <b-button
            variant="secondary"
            class="flex-1"
            @click="onTriggerAction(PAYMENT_METHODS_IDS.PIX)"
            >PIX</b-button
          >
        </div>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  PAYMENT_METHODS_IDS,
  PRODUCT_ACTIONS,
  PRODUCT_DEFAULT_IMAGE
} from '@/constants'
import { formatCurrency } from '@/helpers/numbers'

export default {
  name: 'ProductListItem',
  filters: {
    formatCurrency
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      quantity: 1
    }
  },
  computed: {
    productImage() {
      return this.product.image || PRODUCT_DEFAULT_IMAGE
    }
  },
  created() {
    this.PRODUCT_ACTIONS = PRODUCT_ACTIONS
    this.PAYMENT_METHODS_IDS = PAYMENT_METHODS_IDS
  },
  methods: {
    onTriggerAction(paymentMethod) {
      const { product, quantity } = this

      const data = { quantity, paymentMethod }
      const action = PRODUCT_ACTIONS.BUY

      this.$emit('trigger-action', { action, product, data })
      this.quantity = 1
    },
    increment() {
      this.quantity++
    },
    decrement() {
      this.quantity--
    }
  }
}
</script>

<style lang="scss" scoped>
.card-footer {
  padding: 0px;
}

.actions {
  column-count: 4;
}
.card-title {
  font-size: 1rem;
  font-weight: bold;
}

.display-table {
  display: table;
}
.display-table > div {
  display: table-row;
}
.display-table > div > div {
  display: table-cell;
}

.card {
  max-width: calc((100% / 3) - 0.5em);
  flex: 1 0 calc((100% / 3) - 0.5em);

  @media (max-width: 768px) {
    max-width: calc(100% / 2);
    flex: 1 0 calc((100% / 2) - 0.5em);
  }

  @media (min-width: 1200px) {
    max-width: calc((100% / 4) - 0.5em);
    flex: 1 0 calc((100% / 4) - 0.5em);
  }
}

.btn {
  font-size: 13px;
}
</style>
